import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Layout from '../components/layout'
import Head from '../components/head'
import '../styles/grid.scss'
import '../styles/index.scss'

export const query = graphql`
    query ( $slug : String! ){
        markdownRemark(fields: {slug: {eq: $slug } } ) {
        fields {
          slug
        }
        rawMarkdownBody
        frontmatter{
            title
            date(formatString: "DD.MM.YYYY")
            hashtag
            summary
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        html
        }
    }
`




const Blog = (props) => {
  const richSnipBlog = `
{
  "@context":"http://schema.org",
  "@type": "BlogPosting",
  "image": "https://cember.app`+props.data.markdownRemark.frontmatter.featuredImage.publicURL+`",
  "url": "https://cember.app/blog/`+props.data.markdownRemark.fields.slug+`",
  "headline": "`+props.data.markdownRemark.frontmatter.title+`",
  "alternativeHeadline": "`+props.data.markdownRemark.frontmatter.summary+`",
  "dateCreated": "`+props.data.markdownRemark.frontmatter.date+`",
  "datePublished": "`+props.data.markdownRemark.frontmatter.date+`",
  "dateModified": "`+props.data.markdownRemark.frontmatter.date+`",
  "inLanguage": "tr-TR",
  "isFamilyFriendly": "true",
  "genre":["SEO","JSON-LD"],
  "articleSection": "`+props.data.markdownRemark.frontmatter.hashtag+`",
  "articleBody": "`+props.data.markdownRemark.rawMarkdownBody+`"
}
`

    let featuredImgFluid = props.data.markdownRemark.frontmatter.featuredImage.childImageSharp.fluid
    return (
        <Layout>
            <Head snip={richSnipBlog} title={props.data.markdownRemark.frontmatter.title} description={props.data.markdownRemark.frontmatter.title} />
            <div className="row post">
                <div className="container">
                    <Img fluid={featuredImgFluid} />       
                    <p className="postbilgi">{props.data.markdownRemark.frontmatter.date}
                    <span><b>{props.data.markdownRemark.frontmatter.hashtag}</b></span>
                    </p>
                    <h1>{props.data.markdownRemark.frontmatter.title}</h1>
                    <div dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}></div>
                </div>
            </div>
            
        </Layout>
    )
}

export default Blog